@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ol, ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

input, textarea, button{
  outline: none;
}

::-webkit-scrollbar { width: 8px; height: 8px;}
::-webkit-scrollbar-button {  height: 8px; background-color: #afb1b6; }
::-webkit-scrollbar-track {  background-color: #d2def6;}
::-webkit-scrollbar-track-piece { background-color: #ffffff;}
::-webkit-scrollbar-thumb { height: 50px; background-color: #b8c6e4; border-radius: 0.3em;}
::-webkit-scrollbar-corner { background-color: #d2d4db;}
::-webkit-resizer { background-color: #d2d4db;}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

#print-area {
  display: none;
}

.fullscreenContainer{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fullscreenParent{
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
}

/*@media (max-width: 320px) {*/
  /*.fullscreenParent,*/
  /*.fullscreenContainer{*/
    /*flex: none;}*/
/*}*/

@media print {
  body > * {
    display: none;
    visibility: hidden;
    overflow-x: scroll;
  }

  #print-area {
    display: block;
    overflow: visible;
    visibility: visible;
    /*width: fit-content;*/
    width: 100vw;
  }
}
